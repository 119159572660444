import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep, isEqual } from 'lodash';
import { IClientFieldType } from '../helpers/converters/fieldtype.ts';
import { getUserToken, normalizeEntity } from '../helpers/helpers';
import {
  ActiveEntityPair,
  Bounds,
  DocumentEntity,
  DocumentMetadata,
  EntityComplex,
  EntityTable,
} from '../models/document';
import { ToolType } from '../models/labeler';
import documentSlice from './documentSlice';
import { AppThunk } from './store';
import * as Sentry from '@sentry/react';
export interface LabelerState {
  activeTool: ToolType;
  isEditingMetadata?: boolean;
  activeEntityPair?: ActiveEntityPair;
  activePageNo: number;
  documentEntities: DocumentEntity[];
  documentMetadata: DocumentMetadata[];
  tempEntity?: DocumentEntity;
  activeComplexEditId?: string;
  editingTableEntity?: DocumentEntity;
  selectedEntityTypeId?: string;
  selectedCategoryId?: string;
  activeCell?: { rowIndex: number; colIndex: number };
  tableEditActive: boolean;
  isThumbsVisible: boolean;
  mode: 'label' | 'search' | 'complex';
  externalSearchQueryItem?: { type: string; value: string };
}

const initialState: LabelerState = {
  activeTool: 'default',
  documentEntities: [],
  documentMetadata: [],
  activePageNo: 1,
  tableEditActive: false,
  isThumbsVisible: false,
  mode: 'label',
};

export const labelerSlice = createSlice({
  name: 'labeler',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setActiveTool: (state, action: PayloadAction<ToolType>) => {
      state.activeTool = action.payload;
    },
    setActiveComplexEditId: (state, action: PayloadAction<string>) => {
      state.activeComplexEditId = action.payload;
    },
    setActiveEntityPair: (state, action: PayloadAction<ActiveEntityPair>) => {
      state.activeEntityPair = action.payload;
    },
    setDocumentEntities: (state, action: PayloadAction<DocumentEntity[]>) => {
      state.documentEntities = action.payload;
    },
    setDocumentMetadata: (state, action: PayloadAction<DocumentMetadata[]>) => {
      state.documentMetadata = action.payload;
    },

    setTempEntity: (state, action: PayloadAction<DocumentEntity>) => {
      state.tempEntity = action.payload;
    },

    setActivePageNo: (state, action: PayloadAction<number>) => {
      state.activePageNo = action.payload;
    },

    setIsEditingMetadata: (state, action: PayloadAction<boolean>) => {
      state.isEditingMetadata = action.payload;
    },
    setIsThumbsVisible: (state, action: PayloadAction<boolean>) => {
      state.isThumbsVisible = action.payload;
    },
    setSelectedEntityTypeId: (state, action: PayloadAction<string>) => {
      state.selectedEntityTypeId = action.payload;
    },
    setSelectedCategoryId: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
    },
    setTableEditActive: (state, action: PayloadAction<boolean>) => {
      state.tableEditActive = action.payload;
    },
    setActiveCell: (state, action: PayloadAction<{ rowIndex: number; colIndex: number }>) => {
      state.activeCell = action.payload;
    },
    setActiveMode: (state, action: PayloadAction<'label' | 'search' | 'complex'>) => {
      state.mode = action.payload;
    },
    setExternalSearchQueryItem: (state, action: PayloadAction<{ type: string; value: string }>) => {
      state.externalSearchQueryItem = action.payload;
    },
    setEditingTableEntity: (state, action: PayloadAction<DocumentEntity>) => {
      state.editingTableEntity = action.payload;
    },
  },
});
export const sortEntities = (
  entities: DocumentEntity[],
  allowedEntityTypes: IClientFieldType[]
): DocumentEntity[] => {
  let workList = [...entities];
  workList = workList.map((et) => {
    const clone = cloneDeep(et);

    // Function to get the appropriate valueLocations
    const getValueLocations = (entity: DocumentEntity) => {
      if (entity.value && typeof entity.value === 'object' && 'complex' in entity.value) {
        return entity.value.complex[0]?.valueLocations || [];
      }
      return entity.valueLocations || [];
    };

    const valueLocations = getValueLocations(clone);

    clone.valueLocations = valueLocations
      .map((a) => {
        let obj: any = {};
        if (a.x1)
          obj = {
            x1: parseFloat(a.x1.toFixed(5)),
            x2: parseFloat(a.x2.toFixed(5)),
            y1: parseFloat(a.y1.toFixed(5)),
            y2: parseFloat(a.y2.toFixed(5)),
          } as any;
        if (a.pageNo && a.x1) {
          obj['pageNo'] = a.pageNo;
        }
        return obj;
      })
      .sort((a, b) => {
        return a?.pageNo - b?.pageNo || a?.y1 - b?.y1;
      });

    return clone;
  });

  workList.sort((a: DocumentEntity, b: DocumentEntity) => {
    const getFirstValueLocation = (entity: DocumentEntity): any => {
      if (entity.value && typeof entity.value === 'object' && 'complex' in entity.value) {
        return entity.value.complex[0]?.valueLocations[0] || {};
      }
      return entity.valueLocations[0] || {};
    };

    const aLoc = getFirstValueLocation(a);
    const bLoc = getFirstValueLocation(b);

    // First, sort by page number
    if (a.pageNo !== b.pageNo) {
      return a.pageNo - b.pageNo;
    }

    // If on the same page, sort by entity type order in allowedEntityTypes
    const aTypeIndex = allowedEntityTypes.findIndex((type) => type.id === a.type);
    const bTypeIndex = allowedEntityTypes.findIndex((type) => type.id === b.type);
    if (aTypeIndex !== bTypeIndex) {
      return aTypeIndex - bTypeIndex;
    }

    // If entity types are the same, sort by y1 coordinate
    const aY1 = parseFloat((aLoc?.y1 ?? 0).toFixed(5));
    const bY1 = parseFloat((bLoc?.y1 ?? 0).toFixed(5));
    if (aY1 !== bY1) {
      return aY1 - bY1;
    }

    // If y1 is the same, sort by x1
    const aX1 = parseFloat((aLoc?.x1 ?? 0).toFixed(5));
    const bX1 = parseFloat((bLoc?.x1 ?? 0).toFixed(5));
    if (aX1 !== bX1) {
      return aX1 - bX1;
    }

    // If x1 is the same, sort by y2
    const aY2 = parseFloat((aLoc?.y2 ?? 0).toFixed(5));
    const bY2 = parseFloat((bLoc?.y2 ?? 0).toFixed(5));
    if (aY2 !== bY2) {
      return aY2 - bY2;
    }

    // If y2 is the same, sort by x2
    const aX2 = parseFloat((aLoc?.x2 ?? 0).toFixed(5));
    const bX2 = parseFloat((bLoc?.x2 ?? 0).toFixed(5));
    if (aX2 !== bX2) {
      return aX2 - bX2;
    }

    // If all coordinates are the same, sort by UUID
    return a.uuid.localeCompare(b.uuid);
  });

  return workList;
};

export const setSortedDocumentEntities =
  (documentEntities: DocumentEntity[]): AppThunk =>
  (dispatch, getState) => {
    console.log('documentEntities', documentEntities);
    const allowedEntityTypes = getState().document.allowedEntityTypes ?? [];
    const sorted = sortEntities(documentEntities, allowedEntityTypes);
    // const sorted = sortEntities(documentEntities, allowedEntityTypes ?? []);
    const filtered = sorted.filter((e) => allowedEntityTypes.find((ae) => ae.id === e.type));
    console.log('filtered', filtered);
    dispatch(labelerSlice.actions.setDocumentEntities(filtered));
  };

export const addDocumentEntity =
  (inboxId: string, entity: DocumentEntity): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;

    entity.isPending = 'add';
    // if (entity.valueLocations.length === 1) {
    //   entity.valueLocations[0].pageNo = entity.pageNo;
    // }

    const activeDocument = getState().document.activeDocument;
    const documentEntities = getState().labeler.documentEntities;
    const identicalEntityIndex = documentEntities.findIndex((e) => {
      return (
        e.value === entity.value &&
        e.type === 'TEXT' &&
        e.type === entity.type &&
        e.categoryId === entity.categoryId
      );
    });

    if (identicalEntityIndex !== -1) {
      const newLocations = [
        ...documentEntities[identicalEntityIndex].valueLocations,
        { ...entity.valueLocations[0], pageNo: entity.pageNo },
      ];
      dispatch(
        editDocumentEntity(inboxId, documentEntities[identicalEntityIndex].uuid, {
          valueLocations: newLocations,
        })
      );
      return;
    }
    const newList = [...documentEntities, entity];

    dispatch(setSortedDocumentEntities(newList));
    const normalizedEntity = normalizeEntity(entity, activeDocument);

    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;
    if (activeDocument.parentDocId) {
      url += `${activeDocument.parentDocId}/mutations/${activeDocument.id}/entities`;
    } else {
      url += `${activeDocument.id}/entities`;
    }
    url += `/${normalizedEntity.uuid}`;

    const data = {
      pageNo: normalizedEntity.pageNo,
      type: normalizedEntity.type,
      value: normalizedEntity.value,
      rawValue: normalizedEntity.rawValue,
      valueLocations: normalizedEntity.valueLocations,
      isChecked: normalizedEntity.isChecked,
    } as any;

    if (normalizedEntity.dataURL) data.dataURL = 'image';
    if (normalizedEntity.categoryId) data.categoryId = normalizedEntity.categoryId;

    axios
      .put(url, data, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      })
      .catch(() => {
        dispatch(setSortedDocumentEntities([...documentEntities]));
      })
      .finally(() => {
        dispatch(documentSlice.actions.setIsPatching(false));
      });
  };
type EntityPatchPayload = {
  value?: string | boolean | EntityTable | EntityComplex;
  valueLocations?: Bounds[];
  isChecked?: boolean;
  isSuggestion?: boolean;
  confidence?: number;
  ocrConfidence?: number;
};

export const editDocumentEntity =
  (inboxId: string, entityId: string, changes: EntityPatchPayload, parentId?: string): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;

    const documentEntities = getState().labeler.documentEntities;
    const activeDocument = getState().document.activeDocument;
    const newList: DocumentEntity[] = [...documentEntities];
    // const index = newList.findIndex((item) => item.uuid === entityId);

    const updateEntity = (entity, changes) => {
      const updatedEntity = { ...entity };
      if (changes.confidence !== undefined) updatedEntity.confidence = changes.confidence;
      if (changes.ocrConfidence !== undefined) updatedEntity.ocrConfidence = changes.ocrConfidence;
      if (changes.value !== undefined) updatedEntity.value = changes.value;
      if (changes.valueLocations !== undefined) updatedEntity.valueLocations = changes.valueLocations;
      if (changes.isChecked !== undefined) updatedEntity.isChecked = changes.isChecked;
      if (changes.isSuggestion !== undefined) updatedEntity.isSuggestion = changes.isSuggestion;

      return updatedEntity;
    };

    let changedEntity;

    if (parentId) {
      const parentIndex = newList.findIndex((item) => item.uuid === parentId);
      if (parentIndex === -1) return; // Handle the case where the parent entity is not found

      const parentEntity = cloneDeep(newList[parentIndex]);
      const childEntities = cloneDeep(parentEntity.value['complex']);

      if (!childEntities[entityId]) return; // Handle the case where the child entity is not found

      childEntities[entityId] = updateEntity(childEntities[entityId], changes);

      parentEntity.value['complex'] = childEntities;
      parentEntity.isPending = 'edit';
      newList[parentIndex] = parentEntity;
      changedEntity = parentEntity;
    } else {
      const index = newList.findIndex((item) => item.uuid === entityId);
      if (index === -1) return; // Handle the case where the entity is not found
      newList[index] = { ...updateEntity(newList[index], changes), isPending: 'edit' };
      changedEntity = newList[index];
    }
    dispatch(setSortedDocumentEntities(newList));

    const normalizedEntity = normalizeEntity(changedEntity, activeDocument);

    if (!normalizedEntity) {
      Sentry.captureException('Unable to normalize entity', {
        extra: {
          normalizedEntity,
          changedEntity,
          activeDocument,
        },
      });
      return;
    }
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;

    if (activeDocument.parentDocId) {
      url += `${activeDocument.parentDocId}/mutations/${activeDocument.id}/entities/${normalizedEntity.uuid}`;
    } else {
      url += `${activeDocument.id}/entities/${normalizedEntity.uuid}`;
    }
    const patchData = {
      value: normalizedEntity.value,
      valueLocations: normalizedEntity.valueLocations,
    };

    if (changes.confidence != null) {
      patchData['confidence'] = changes.confidence;
    }
    if (changes.ocrConfidence != null) {
      patchData['ocrConfidence'] = changes.ocrConfidence;
    }
    if (changes.isChecked != null) {
      patchData['isChecked'] = changes.isChecked;
    }
    if (changes.isSuggestion != null) {
      patchData['isSuggestion'] = changes.isSuggestion;
    }

    axios
      .patch(url, patchData, {
        withCredentials: true,
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      })
      .then(() => {
        dispatch(documentSlice.actions.setIsPatching(false));
      });
  };

export const unlinkEntities =
  (inboxId: string, entities: DocumentEntity[]): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;

    const activeDocument = getState().document.activeDocument;
    const copyStructure = getState().document.copyStructure;
    const activeEntities = getState().labeler.documentEntities;
    const newList = activeEntities.map((e) => {
      if (entities.includes(e)) {
        return { ...e, isPending: 'delete' } as DocumentEntity;
      } else return e;
    });

    dispatch(setSortedDocumentEntities(newList));

    if (!copyStructure) return;
    const docId = copyStructure.originalDoc?.id;
    let mutationId;
    if (activeDocument?.id !== docId) mutationId = activeDocument.id;

    let url = `${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/documents/${docId}`;
    if (activeDocument.parentDocId) {
      url = url + `/mutations/${mutationId}/unlink`;
    } else {
      url = url + '/unlink';
    }
    axios
      .post(url, null, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      })
      .then(() => {
        dispatch(documentSlice.actions.setIsPatching(false));
      })
      .catch(() => {
        const newList = [...entities].map((e) => {
          return { ...e, isPending: null };
        });
        dispatch(setSortedDocumentEntities(newList));
      });
  };

export const deleteDocumentEntities =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;

    const activeDocument = getState().document.activeDocument;
    const activeEntities = getState().labeler.documentEntities;

    const newList = activeEntities.map((e) => {
      return { ...e, isPending: 'delete' } as DocumentEntity;
    });

    dispatch(setSortedDocumentEntities(newList));

    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;
    if (activeDocument.parentDocId) {
      url += `${activeDocument.parentDocId}/mutations/${activeDocument.id}/entities`;
    } else {
      url += `${activeDocument.id}/entities`;
    }
    const ids = newList.map((e) => e.uuid);
    axios
      .delete(url, {
        params: {
          ids: ids.join(','),
        },
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      })
      .then(() => {
        dispatch(documentSlice.actions.setIsPatching(false));
      })
      .catch(() => {
        const newList = [...activeEntities].map((e) => {
          return { ...e, isPending: null };
        });
        dispatch(setSortedDocumentEntities(newList));
      });
  };

export const deleteDocumentEntity =
  (inboxId: string, entityPair: ActiveEntityPair): AppThunk =>
  async (dispatch, getState) => {
    const { entityId, locationIndex } = entityPair;
    const b = await getUserToken();
    if (!b) return;

    const {
      labeler: { documentEntities },
      document: { activeDocument },
    } = getState();
    const index = documentEntities.findIndex((item) => isEqual(item.uuid, entityId));

    const url =
      `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/` +
      (activeDocument.parentDocId
        ? `${activeDocument.parentDocId}/mutations/${activeDocument.id}/entities/${entityId}`
        : `${activeDocument.id}/entities/${entityId}`);

    const headers = {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    };

    // Handle the case where index is not found
    if (index < 0) return;

    if (documentEntities[index].valueLocations.length > 1 && locationIndex !== null) {
      const changedEntityLocations = [...documentEntities[index].valueLocations];
      changedEntityLocations.splice(locationIndex, 1);
      const changedEntity = { ...documentEntities[index], valueLocations: changedEntityLocations };
      const normalizedEntity = normalizeEntity(changedEntity, activeDocument);

      axios
        .patch(url, { valueLocations: normalizedEntity.valueLocations }, { headers, withCredentials: true })
        .then(() => {
          dispatch(documentSlice.actions.setIsPatching(false));
        });
    } else if (entityPair.childId) {
      const ent = documentEntities[index];
      const entValue = cloneDeep(ent.value) as EntityComplex;

      entValue.complex[entityPair.childId] = {
        ...entValue.complex[entityPair.childId],
        value: null,
        rawValue: null,
        dataURL: null,
        valueLocations: [],
      };
      const newList = [...documentEntities];
      newList[index] = { ...newList[index], value: entValue, isPending: 'edit' };
      dispatch(setSortedDocumentEntities(newList));
      Object.entries(entValue.complex).forEach(([key, value]) => {
        entValue.complex[key] = normalizeEntity(value as any, activeDocument) as any;
      });
      axios.patch(url, { value: entValue }, { headers, withCredentials: true }).then(() => {
        dispatch(documentSlice.actions.setIsPatching(false));
      });
    } else {
      const newList = [...documentEntities];
      newList[index] = { ...newList[index], isPending: 'delete' };
      dispatch(setSortedDocumentEntities(newList));

      axios.delete(url, { headers }).then(() => {
        dispatch(documentSlice.actions.setIsPatching(false));
      });
    }
  };

export default labelerSlice.reducer;
